/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useState, useEffect, useRef } from "react";
import { initialState, reducer } from "../components/ReducerComponent";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import FailureModal from "../components/FailureModal";
import { Modal } from "flowbite-react";

function Signup() {
  const [loadingState, dispatch] = useReducer(reducer, initialState);
  const documentBodyRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "location");
  const { pathname } = location;
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const URL = `${origin}${pathname}`;
  const [signUpdata, setsignUpdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    af_code: null,
    ref_code: null,
    plan_id: 1,
  });
  const [subscribeToModalOpen, setsubscribeToModalOpen] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const emailref = useRef(null);
  // console.log(loadingState, "loading state");

  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setsignUpdata({ ...signUpdata, [id]: value });
  };
  const signUpButton = async (e) => {
    e.preventDefault();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    dispatch({ type: "LOADING" });
    let data = {
      ...signUpdata,
    };
    delete data.af_code;
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/signup`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      if (response.data.success) {
        dispatch({
          type: "SUCCESS",
          data: {
            successMessage: response.data.message,
          },
        });
        navigate("/signup-otp", {
          replace: true,
          state: {
            email: signUpdata.email,
            af_code: signUpdata.af_code,
            ref_code: signUpdata.ref_code,
          },
        });
      } else {
        if (response.data.email) {
          setsuccessFailureMessage(response.data.message);
          setopenFailureModal("dismissible");
          dispatch({
            type: "ERROR",
            data: {
              errorMessage: "",
            },
          });
        } else {
          dispatch({
            type: "ERROR",
            data: {
              errorMessage: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        data: {
          errorMessage: error?.response?.data?.msg,
        },
      });
      console.log(error);
    }
  };
  const callMissingUserApi = async (email) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/missed/user`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: email,
          page_name: URL,
        },
      });
      if (response.data.success) {
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // Extract email from the URL
    const urlParams = new URLSearchParams(location.search);
    const emailParam = urlParams.get("email");
    const af_code = urlParams.get("af_code");
    const ref_code = urlParams.get("ref_code");
    const plan = urlParams.get("plan");
    if (emailParam !== null) {
      // console.log(emailParam, "email param");
      callMissingUserApi(emailParam);
      setsignUpdata({ ...signUpdata, email: emailParam });
      navigate("/signup");
    }
    if (plan) {
      let plan_id = 1;
      if (plan === "freemium") {
        plan_id = 1;
      } else if (plan === "lite") {
        plan_id = 2;
      } else if (plan === "pro") {
        plan_id = 3;
      }
      localStorage.setItem("visonsignupplan", plan_id);
      setsignUpdata({ ...signUpdata, plan_id: plan_id });
      navigate("/signup");
    } else {
      localStorage.setItem("visonsignupplan", 1);
    }
    if (af_code) {
      setsignUpdata({ ...signUpdata, af_code: af_code, ref_code: af_code });
      localStorage.setItem("ref_code", af_code);
    } else {
      localStorage.removeItem("ref_code");
    }
    if (ref_code) {
      setsignUpdata({ ...signUpdata, ref_code: ref_code });
      localStorage.setItem("ref_code", ref_code);
    } else {
      localStorage.removeItem("ref_code");
    }
  }, []);
  const timeoutRef = useRef(null);
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    // setemail(newEmail);
    setsignUpdata({ ...signUpdata, email: newEmail });

    // Clear any previous timeout when the user is typing
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    // Set a new timeout for 3 seconds after typing stops
    timeoutRef.current = setTimeout(() => {
      if (newEmail && validateEmail(newEmail)) {
        axios
          .post(`${process.env.REACT_APP_BASE_SIGNIN_URL}/missed/user`, {
            email: newEmail,
            page_name: URL,
          })
          .then((response) => {
            console.log("API response:", response.data);
          })
          .catch((error) => {
            console.error("API error:", error);
          });
        // Call your API here
        console.log("Valid email, calling API...");
      }
    }, 2000);
  };
  // useEffect(() => {
  //   const handleMouseOut = (event) => {
  //     // Trigger when the mouse leaves the viewport (indicating the user might close the tab)
  //     if (!event.relatedTarget && event.clientY < 0) {
  //       sendEmailData();
  //     }
  //   };

  //   const validateEmail = (email) => {
  //     return String(email)
  //       .toLowerCase()
  //       .match(
  //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //       );
  //   };

  //   const sendEmailData = () => {
  //     const emailValue = emailref.current?.value;

  //     if (emailValue && validateEmail(emailValue)) {
  //       axios
  //         .post(`${process.env.REACT_APP_BASE_SIGNIN_URL}/missed/user`, {
  //           email: emailValue,
  //           page_name: URL,
  //         })
  //         .then((response) => {
  //           console.log("API response:", response.data);
  //         })
  //         .catch((error) => {
  //           console.error("API error:", error);
  //         });
  //     }
  //   };

  //   window.addEventListener("mouseout", handleMouseOut);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener("mouseout", handleMouseOut);
  //   };
  // }, []);

  useEffect(() => {
    localStorage.setItem("visonsignupplan", signUpdata?.plan_id);
  }, [signUpdata?.plan_id]);

  console.log(signUpdata, "signup data");
  return (
    <>
      <div className="h-screen">
        <img
          className="loginfram w-full h-full absolute z-[-10]"
          src="images/frame.png"
          alt="..."
        />
        <div class="flex flex-wrap -mx-6l h-screen">
          <div class="w-full md:w-1/2 px-[80px] flex items-center justify-center">
            <div className="w-[420px]">
              <img className="w-full" src="images/signup.png" alt="..." />
            </div>
          </div>
          <div class="w-full md:w-1/2 px-[80px] flex items-center">
            <form className="w-full" onSubmit={signUpButton}>
              <div
                className="flex justify-center cursor-pointer"
                onClick={() => window.open(process.env.REACT_APP_LANDING_URL)}
              >
                {/* <img src="images/logo.png" alt="..." /> */}
                <img
                  src="images/VisonWithOReplaced.png"
                  width={150}
                  height={50}
                  alt="..."
                />
              </div>
              <h5 className="font-extrabold text-[40px] pb-2 pt-1">Sign Up</h5>
              <div className="mb-[15px]">
                <label
                  className="block mb-[2px] font-semibold text-[20px]"
                  htmlFor="first_name"
                >
                  First Name
                </label>
                <input
                  type="text"
                  className="w-full rounded-full font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter first name"
                  id="first_name"
                  required
                  value={signUpdata.first_name}
                  onChange={handleChange}
                  pattern=".*\S+.*"
                />
              </div>
              <div className="mb-[15px]">
                <label
                  class="block mb-[2px] font-semibold text-[20px]"
                  htmlFor="last_name"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  className="w-full rounded-full font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter last name"
                  id="last_name"
                  required
                  value={signUpdata.last_name}
                  onChange={handleChange}
                  pattern=".*\S+.*"
                />
              </div>
              <div className="mb-[15px]">
                <label
                  class="block mb-[2px] font-semibold text-[20px]"
                  htmlFor="email"
                >
                  Email Address (Personal Or Work)
                </label>
                <input
                  ref={emailref}
                  type="email"
                  className="w-full rounded-full font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter email"
                  required
                  id="email"
                  value={signUpdata.email}
                  // onChange={handleChange}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="mb-[10px]">
                <label className="block mb-[2px] font-semibold text-[20px]">
                  Subscribe To
                </label>
                <div className="flex flex-row gap-5 text-[18px]">
                  <label className="mb-[5px]">
                    <input
                      type="radio"
                      name="subscription_plan"
                      value="freemium"
                      checked={signUpdata.plan_id === 1}
                      onClick={() => {
                        setsignUpdata({ ...signUpdata, plan_id: 1 });
                      }}
                      required
                    />
                    <span className="ml-1">Freemium</span>
                  </label>
                  <label className="mb-[5px]">
                    <input
                      type="radio"
                      name="subscription_plan"
                      value="lite"
                      checked={signUpdata.plan_id === 2}
                      onClick={() => {
                        setsignUpdata({ ...signUpdata, plan_id: 2 });
                      }}
                      required
                    />
                    <span className="ml-1">Lite 14 days free trial</span>
                  </label>
                  <label className="mb-[5px]">
                    <input
                      type="radio"
                      name="subscription_plan"
                      checked={signUpdata.plan_id === 3}
                      onClick={() => {
                        setsignUpdata({ ...signUpdata, plan_id: 3 });
                      }}
                      value="pro"
                      required
                    />
                    <span className="ml-1">Pro 14 days free trial</span>
                  </label>
                </div>
              </div>
              {loadingState.error && (
                <div className="w-full text-[red] py-2 text-center">
                  {loadingState.errorMessage}
                </div>
              )}
              <button
                type="submit"
                disabled={loadingState.loading}
                className="w-full mt-2 justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              >
                {loadingState.loading ? (
                  <div className="flex justify-between items-center">
                    <div></div>
                    <div>Signing In</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
              <div
                className="py-2 text-center font-bold text-[18px] cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                <span>Already Have An Account </span>
                <a className="text-transparent bg-clip-text bg-gradient-to-r from-[#1A71A7] to-[#9D3784] hover:from-[#9D3784] hover:to-[#1A71A7]">
                  Sign In
                </a>
              </div>
              {/* <hr /> */}
              <h3 className="title-around-span ">
                <span>Or</span>
              </h3>

              <button
                type="button"
                className="w-full my-1 mt-2 justify-center py-2 px-[20px] rounded-[20px] font-semibold border border-[#D73939] text-[#D73939] relative hover:bg-[#D73939] hover:text-[#ffffff] transition duration-700"
                onClick={() => {
                  setsubscribeToModalOpen(true);
                  // window.open(
                  //   `${process.env.REACT_APP_BASE_SIGNIN_URL}/auth/google`,
                  //   "_self"
                  // );
                }}
              >
                <span className="absolute left-[20px] top-[6px]">
                  <svg className="icon text-[28px]">
                    <use href="#icon_google"></use>
                  </svg>
                </span>
                Continue With Google
              </button>
              <hr />
              <div className="py-6 text-center">
                <span className="">
                  By continuing, you are agree to Vison's&nbsp;
                  <a
                    href={process.env.REACT_APP_LANDING_URL + "/saas-agreement"}
                    rel="noreferrer"
                    target="_blank"
                    className="text-[#1B5AB9] hover:text-[#9D3784]"
                  >
                    SaaS&nbsp;Agreement,
                  </a>
                  &nbsp;
                  <a
                    href={process.env.REACT_APP_LANDING_URL + "/terms"}
                    rel="noreferrer"
                    target="_blank"
                    className="text-[#1B5AB9] hover:text-[#9D3784]"
                  >
                    Terms of Service
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href={process.env.REACT_APP_LANDING_URL + "/privacy"}
                    rel="noreferrer"
                    target="_blank"
                    className="text-[#1B5AB9] hover:text-[#9D3784]"
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
        from="signup"
        email={signUpdata.email}
      />
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={subscribeToModalOpen}
        size="2xl"
        onClose={() => setsubscribeToModalOpen(false)}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">
            <label className="block mb-[2px] font-semibold text-[20px]">
              Subscribe To
            </label>
          </h6>
        </Modal.Header>
        <Modal.Body className="pt-2">
          <div className="mb-[10px]">
            <div className="flex flex-row gap-5 text-[18px]">
              <label className="mb-[5px]">
                <input
                  type="radio"
                  name="subscription_plan"
                  value="freemium"
                  checked={signUpdata.plan_id === 1}
                  onClick={() => {
                    setsignUpdata({ ...signUpdata, plan_id: 1 });
                  }}
                  required
                />
                <span className="ml-1">Freemium</span>
              </label>
              <label className="mb-[5px]">
                <input
                  type="radio"
                  name="subscription_plan"
                  value="lite"
                  checked={signUpdata.plan_id === 2}
                  onClick={() => {
                    setsignUpdata({ ...signUpdata, plan_id: 2 });
                  }}
                  required
                />
                <span className="ml-1">Lite 14 days free trial</span>
              </label>
              <label className="mb-[5px]">
                <input
                  type="radio"
                  name="subscription_plan"
                  checked={signUpdata.plan_id === 3}
                  onClick={() => {
                    setsignUpdata({ ...signUpdata, plan_id: 3 });
                  }}
                  value="pro"
                  required
                />
                <span className="ml-1">Pro 14 days free trial</span>
              </label>
            </div>
            <button
                type="button"
                className="w-full  mt-3 justify-center py-2 px-[20px] rounded-[20px] font-semibold border border-[#D73939] text-[#D73939] relative hover:bg-[#D73939] hover:text-[#ffffff] transition duration-700"
                onClick={() => {
                  // setsubscribeToModalOpen(true);
                  window.open(
                    `${process.env.REACT_APP_BASE_SIGNIN_URL}/auth/google`,
                    "_self"
                  );
                }}
              >
                <span className="absolute left-[20px] top-[6px]">
                  <svg className="icon text-[28px]">
                    <use href="#icon_google"></use>
                  </svg>
                </span>
                Continue With Google
              </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Signup;
