/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initialState, reducer } from "../components/ReducerComponent";
import axios from "axios";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import { Modal } from "flowbite-react";
import ReferalModalPopUp from "../components/ReferalModalPopUp";
import useCommonApi from "../hooks/common-apis";

function Signup2() {
  const location = useLocation();
  const [loadingState, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [signUpdata, setsignUpdata] = useState({
    email: "",
    verifytxt: "",
    ref_code: "",
  });
  const { afRefCodeFn } = useCommonApi();
  // console.log(loadingState, "loading state");
  const documentBodyRef = useRef();

  const [count, setCount] = useState(60);
  const [isCounting, setIsCounting] = useState(true);
  const [loading, setloading] = useState(false);
  const [openModalReferal, setopenModalReferal] = useState(undefined);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setsignUpdata({ ...signUpdata, [id]: value });
  };
  const signUpButton = async (e) => {
    localStorage.removeItem("login_token");
    localStorage.removeItem("login_data");
    localStorage.removeItem("workspaces_list");
    localStorage.removeItem("selected_workspace");
    localStorage.removeItem("selectedContentSettings");
    localStorage.removeItem("template_selected");
    localStorage.removeItem("assigning_template_to_file");
    localStorage.removeItem("selected_file");
    localStorage.removeItem("chat_details");
    localStorage.removeItem("chat_file");
    e.preventDefault();
    dispatch({ type: "LOADING" });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/otp/verify`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: location?.state?.email,
          verifytxt: signUpdata.verifytxt,
        },
      });
      if (response.data.success) {
        dispatch({ type: "RESET" });
        localStorage.setItem("login_data", JSON.stringify(response.data.data));
        localStorage.setItem("login_token", response.data.token);
        let data = response.data.data;
        if (data?.workspacess?.length == 0) {
          localStorage.setItem("workspaces_list", JSON.stringify([]));
          localStorage.setItem("selected_workspace", JSON.stringify({}));
        } else {
          let workspace_id = data.resp_user.workspace_id;
          if (workspace_id) {
            let workspace = data.workspacess.find(
              (item) => item.id == workspace_id
            );
            if (workspace) {
              localStorage.setItem(
                "selected_workspace",
                JSON.stringify(workspace || {})
              );
            }
          }
        }
        if (!location?.state?.ref_code) {
          setopenModalReferal("dismissible");
        } else {
          if (location?.state?.ref_code) {
            dispatch({ type: "LOADING" });
            afRefCodeFn(location?.state?.ref_code);
            dispatch({ type: "RESET" });
          } else {
            navigate("/template", {
              replace: true,
            });
          }
        }
      } else {
        dispatch({
          type: "ERROR",
          data: {
            errorMessage: response.data.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        data: {
          errorMessage: error?.response?.data?.msg,
        },
      });
      console.log(error);
    }
  };
  const sendOtpFn = async (e) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/vison/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: location?.state?.email,
        },
      });
      if (response.data.success) {
        dispatch({
          type: "SUCCESS",
          data: {
            successMessage: response.data.message,
          },
        });
      } else {
        dispatch({
          type: "ERROR",
          data: {
            errorMessage: response.data.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        data: {
          errorMessage: error?.response?.data?.msg,
        },
      });
      console.log(error);
    }
  };
  const startCountdown = () => {
    dispatch({ type: "RESET" });
    setCount(60);
    setIsCounting(true);
    sendOtpFn();
  };

  useEffect(() => {
    let interval;

    if (isCounting && count > 0) {
      interval = setInterval(() => {
        setCount(count - 1);
      }, 1000);
    }

    if (count === 0 || !isCounting || loadingState.loading) {
      clearInterval(interval);
      setIsCounting(false);
    }

    return () => clearInterval(interval);
  }, [count, isCounting, loadingState]);
  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);
  return (
    <>
      <div className="h-screen">
        <img
          className="loginfram w-full h-full absolute z-[-10]"
          src="images/frame.png"
          alt="..."
        />
        <div class="flex flex-wrap -mx-6l h-screen">
          <div class="w-full md:w-1/2 px-[80px] flex items-center justify-center">
            <div className="w-[420px]">
              <img className="w-full" src="images/signup2.png" alt="..." />
            </div>
          </div>
          <div class="w-full md:w-1/2 px-[80px] flex items-center">
            <form className="w-full" onSubmit={signUpButton}>
              <div className="flex justify-center">
                {/* <img src="images/logo.png" alt="..." /> */}
                <img
                  src="images/VisonWithOReplaced.png"
                  width={150}
                  height={50}
                  alt="..."
                />
              </div>
              <h5 className="font-extrabold text-[40px] py-3">Sign Up</h5>
              <p className="font-bold text-[20px] mb-[20px]">
                Enter the code we sent to {location?.state?.email || ""}
              </p>
              <div className="mb-[15px]">
                <label
                  class="block mb-[2px] font-semibold text-[20px]"
                  htmlFor="verifytxt"
                >
                  Enter Verification Code
                </label>
                <input
                  type="number"
                  className="w-full rounded-full font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Code"
                  required
                  id="verifytxt"
                  onChange={handleChange}
                />
              </div>
              {loadingState.successMessage != "" && (
                <div className="w-full text-[red] py-2 text-center">
                  {loadingState.successMessage}
                </div>
              )}
              {loadingState.error && (
                <div className="w-full text-[red] py-2 text-center">
                  {loadingState.errorMessage}
                </div>
              )}
              <button
                type="submit"
                className="w-full mt-2 justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              >
                {/* {loadingState.loading ? (
                  <div className="flex justify-between items-center">
                    <div></div>
                    <div>Finishing Sign Up</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Finish Sign Up"
                )} */}
                <div className="flex justify-between items-center">
                  <div></div>
                  <div>
                    {loadingState.loading
                      ? "Finishing Sign Up"
                      : "Finish Sign Up"}
                  </div>
                  <div>
                    {loadingState.loading ? <ThreeDotsLoader /> : <></>}
                  </div>
                </div>
              </button>
              <div
                className="py-5 text-center font-bold text-[18px] cursor-pointer flex justify-center items-center gap-2"
                onClick={startCountdown}
              >
                <button
                  disabled={isCounting}
                  className={`group text-transparent bg-clip-text 
                  bg-gradient-to-r ${
                    isCounting
                      ? "from-[#B0B0B0] to-[#B0B0B0]"
                      : "from-[#1A71A7] to-[#9D3784] hover:from-[#9D3784] hover:to-[#1A71A7]"
                  }
                `}
                >
                  Resend Code
                </button>
                {isCounting ? <>{count}</> : ""}
              </div>
              <hr />
              <div className="vaisnavi py-5">
                <span className="py-6">
                  By continuing, you are agree to Vison’s{" "}
                  <a href="#" className="hover:text-[#9D3784]">
                    Terms of Service
                  </a>{" "}
                  Read Our{" "}
                  <a href="#" className="hover:text-[#1A71A7] ">
                    Privacy Policy
                  </a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <Modal
        root={documentBodyRef.current}
        show={openModalReferal === "dismissible"}
        size="lg"
        onClose={() => {
          navigate("/template");
          setopenModalReferal(undefined);
        }}
      >
        <Modal.Body className="pt-0">
          <div className="p-5">
            <img
              src={window.location.origin + "/images/affliate_referal.svg"}
              alt="Referal"
              className="w-100 px-4"
            />
          </div>
          <h6 className="font-bold text-[24px]">
            Do You Have A <span>Referral Code?</span>
          </h6>
          <div className="pb-3 pt-2">
            <label for="name"></label>
            <input
              type="text"
              name="name"
              id="name"
              value={signUpdata.ref_code}
              placeholder="Enter Code"
              onChange={(e) => {
                setsignUpdata({ ...signUpdata, ref_code: e.target.value });
              }}
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
            />
          </div>

          <div className="flex justify-center items-center pt-4">
            <div>
              <button
                type="button"
                className="border border-[#2A2A2A] rounded-full px-14 py-2 me-6"
                onClick={() => {
                  navigate("/template");
                }}
              >
                Skip
              </button>
              <button
                type="submit"
                disabled={loading}
                className="text-[#fff] px-14 py-2 font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] hover:from-[#9D3784] hover:to-[#1A71A7] rounded-[20px]"
                onClick={() => {
                  afRefCodeFn(signUpdata.ref_code);
                }}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div>Submitting</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      <ReferalModalPopUp
        afRefCodeFn={afRefCodeFn}
        documentBodyRef={documentBodyRef}
        loading={loading}
        setloading={setloading}
        openModalReferal={openModalReferal}
        setopenModalReferal={setopenModalReferal}
        setsignUpdata={setsignUpdata}
        signUpdata={signUpdata}
      />
    </>
  );
}

export default Signup2;
